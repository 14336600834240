import { useState } from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import BrandButton from "components/BrandButton";
import ResponsiveMainVideo from "./ResponsiveMainVideo";
import AutonomousVideo from "./autonomousVideo";
import ThermalSteakVideo from "./thermalSteakVideo";

const navigation = [
    { name: "Home", href: "/" },
    // { name: "Blog", href: "/updates" },
];

const tabs = [
    {
        name: "Autonomous",
        features: [
            {
                name: "Adaptive and modular",
                description:
                    "Zero-touch, probeless cooking with our AI powered cooking device",
                imageSrc:
                    "https://tailwindui.com/plus/img/ecommerce-images/product-feature-06-detail-01.jpg",
                imageAlt:
                    "Maple organizer base with slots, supporting white polycarbonate trays of various sizes.",
            },
        ],
    },
    {
        name: "Precise",
        features: [
            {
                name: "Natural wood options",
                description: "Free up your time while meals cook perfectly",
                imageSrc:
                    "https://tailwindui.com/plus/img/ecommerce-images/product-feature-06-detail-02.jpg",
                imageAlt:
                    "Walnut organizer base with pen, sticky note, phone, and bin trays, next to modular drink coaster attachment.",
            },
        ],
    },
    {
        name: "Personalized",
        features: [
            {
                name: "Helpful around the home",
                description: "Achieve consistent, delicious results every time",
                imageSrc:
                    "https://tailwindui.com/plus/img/ecommerce-images/product-feature-06-detail-03.jpg",
                imageAlt:
                    "Walnut organizer base with white polycarbonate trays in the kitchen with various kitchen utensils.",
            },
        ],
    },
];

export default function Presentation() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [videoEnded, setVideoEnded] = useState(false);
    const [email, setEmail] = useState("");
    const [status, setStatus] = useState("idle"); // idle, loading, success, error
    const [error, setError] = useState("");

    const validateEmail = (email) => {
        return email.includes("@");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateEmail(email)) {
            setError("Please enter a valid email address");
            return;
        }

        setStatus("loading");
        setError("");

        try {
            const response = await fetch(
                "https://m2gl1bz6w3.execute-api.us-east-1.amazonaws.com/development/homepage/newsletter",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ email }),
                }
            );

            if (!response.ok) {
                throw new Error("Failed to subscribe");
            }

            setStatus("success");
            setEmail("");
        } catch (err) {
            setStatus("error");
            setError("Failed to subscribe. Please try again.");
        }
    };

    return (
        <>
            <div className='bg-gray-900'>
                <header className='absolute inset-x-0 top-0 z-50'>
                    <nav
                        aria-label='Global'
                        className='container flex items-center justify-between p-6 lg:px-8'
                    >
                        <div className='flex lg:flex-1'>
                            <a href='#' className='-m-1.5 p-1.5'>
                                <span className='sr-only'>CHEF AI</span>
                                <img
                                    alt=''
                                    src='https://chefaicookware.com/chefai-transparent.svg'
                                    className='h-14 w-auto'
                                />
                            </a>
                        </div>
                        <div className='flex lg:hidden d-none'>
                            <button
                                type='button'
                                onClick={() => setMobileMenuOpen(true)}
                                className='-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400'
                            >
                                <span className='sr-only'>Open main menu</span>
                                <Bars3Icon
                                    aria-hidden='true'
                                    className='h-6 w-6'
                                />
                            </button>
                        </div>
                        <div
                            className='hidden lg:flex lg:gap-x-12'
                            style={{
                                fontFamily:
                                    '"Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                            }}
                        >
                            {navigation.map((item) => (
                                <a
                                    key={item.name}
                                    href={item.href}
                                    className='text-lg/6 font-semibold text-white'
                                >
                                    {item.name}
                                </a>
                            ))}
                        </div>
                        {/* <div className='hidden lg:flex lg:flex-1 lg:justify-end'>
                            <a
                                href='/login'
                                className='text-sm/6 font-semibold text-white'
                            >
                                Log in <span aria-hidden='true'>&rarr;</span>
                            </a>
                        </div> */}
                    </nav>
                    <Dialog
                        open={mobileMenuOpen}
                        onClose={setMobileMenuOpen}
                        className='lg:hidden d-none'
                    >
                        <div className='fixed inset-0 z-50' />
                        <DialogPanel className='fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-gray-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10'>
                            <div className='flex items-center justify-between'>
                                <a href='#' className='-m-1.5 p-1.5'>
                                    <span className='sr-only'>CHEFAI</span>
                                    <img
                                        alt=''
                                        src='https://chefaicookware.com/chefai-transparent.svg'
                                        className='h-8 w-auto'
                                    />
                                </a>
                                <button
                                    type='button'
                                    onClick={() => setMobileMenuOpen(false)}
                                    className='-m-2.5 rounded-md p-2.5 text-gray-400'
                                >
                                    <span className='sr-only'>Close menu</span>
                                    <XMarkIcon
                                        aria-hidden='true'
                                        className='h-6 w-6'
                                    />
                                </button>
                            </div>
                            <div className='mt-6 flow-root'>
                                <div className='-my-6 divide-y divide-gray-500/25'>
                                    <div
                                        className='space-y-2 py-6'
                                        style={{
                                            fontFamily:
                                                '"Roboto", "Helvetica Neue"',
                                        }}
                                    >
                                        {navigation.map((item) => (
                                            <a
                                                key={item.name}
                                                href={item.href}
                                                className='-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-white hover:bg-gray-800'
                                            >
                                                {item.name}
                                            </a>
                                        ))}
                                    </div>
                                    <div className='py-6'>
                                        <a
                                            href='#'
                                            className='-mx-3 block rounded-lg px-3 py-2.5 text-base/7 font-semibold text-white hover:bg-gray-800'
                                        >
                                            Log in
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </DialogPanel>
                    </Dialog>
                </header>

                <div className='relative isolate overflow-hidden pt-14'>
                    <div className='absolute inset-0 -z-10'>
                        <ResponsiveMainVideo />
                    </div>

                    <div
                        aria-hidden='true'
                        className='absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80'
                    >
                        <div
                            style={{
                                clipPath:
                                    "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                            }}
                            className='relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-10 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]'
                        />
                    </div>

                    <div className='mx-auto max-w-7xl px-6 lg:px-8'>
                        <div className='mx-auto max-w-2xl py-32 sm:py-48 lg:py-56'>
                            <div className='hidden sm:mb-8 sm:flex sm:justify-center d-none'>
                                <div className='relative rounded-full px-3 py-1 text-sm/6 text-gray-400 ring-1 ring-white/10 hover:ring-white/20'>
                                    Announcing our next round of funding.{" "}
                                    <a
                                        href='#'
                                        className='font-semibold text-white'
                                    >
                                        <span
                                            aria-hidden='true'
                                            className='absolute inset-0'
                                        />
                                        Read more{" "}
                                        <span aria-hidden='true'>&rarr;</span>
                                    </a>
                                </div>
                            </div>
                            <div className='text-center'>
                                <h1 className='text-balance text-5xl font-semibold tracking-tight text-white sm:text-7xl'>
                                    Perfect Meals Zero Effort
                                </h1>
                                <p className='mt-8 text-pretty text-lg font-medium text-gray-200 sm:text-xl/8'>
                                    Your personal{" "}
                                    <span className='text-gold'>CHEF AI </span>
                                    is here.
                                </p>
                                <div className='mt-10 flex items-center justify-center gap-x-6'>
                                    <BrandButton href='https://calendly.com/chefai-llc'>
                                        Contact for Demo
                                    </BrandButton>
                                    <BrandButton href='/#newsletter'>
                                        Sign up for Newsletter
                                    </BrandButton>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        aria-hidden='true'
                        className='absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]'
                    >
                        <div
                            style={{
                                clipPath:
                                    "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                            }}
                            className='relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-10 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]'
                        />
                    </div>
                </div>
            </div>
            <div className='container pt-5 sm:px-2 md:px-5 lg:px-5 xl:px-5 text-gold'>
                <div className='text-center'>
                    <div className='col-md-12'>
                        <div className='bg-transparent text-white py-1 md:py-5 px-2 md:px-4 container-fluid'>
                            <div className='container'>
                                <p className='fs-3 fs-sm-6 lh-base letter-spacing-1'>
                                    At CHEF AI, we're pioneering autonomous
                                    cooking technology. With zero-touch
                                    operation and no external probes needed,
                                    we've reimagined convenience in the kitchen.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='gradient'>
                <div className='container d-none d-lg-block'>
                    <div className='row row-cols-1 row-cols-md-4 g-4'>
                        <div className='col'>
                            <a
                                href='https://www.abc27.com/news/us-world/business/ap-tech-companies-are-transforming-the-kitchen-with-ai-and-robots-that-do-the-cooking-2/'
                                target='_blank'
                            >
                                <div className='card bg-transparent'>
                                    <img
                                        src='./abc_news_2.png'
                                        className='card-img-top'
                                        alt='...'
                                    />
                                </div>
                            </a>
                        </div>
                        <div className='col'>
                            <a
                                href='https://www.wired.com/live/ces-2024-liveblog-3/?id=65a0272c36bab5b3557be6e7'
                                target='_blank'
                            >
                                <div className='card bg-transparent'>
                                    <img
                                        src='./wired.png'
                                        className='card-img-top'
                                        alt='...'
                                    />
                                </div>
                            </a>
                        </div>
                        <div className='col'>
                            <a
                                href='https://finance.yahoo.com/news/tech-companies-transforming-kitchen-ai-152403339.html'
                                target='_blank'
                            >
                                <div className='card bg-transparent'>
                                    <img
                                        src='./yahoo.png'
                                        className='card-img-top'
                                        alt='...'
                                    />
                                </div>
                            </a>
                        </div>
                        <div className='col'>
                            <a
                                href='https://www.forbes.com/sites/andrewwatman/2025/01/08/ces-2025-best-tech-products-in-smart-cooking/'
                                target='_blank'
                            >
                                <div className='card bg-transparent'>
                                    <img
                                        src='./forbes_2.png'
                                        className='card-img-top'
                                        alt='...'
                                    />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

                <div className='bg-black sm:py-3 d-lg-none'>
                    <div className='mx-auto max-w-7xl px-6 lg:px-8'>
                        <div className='mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-4 sm:gap-x-4 lg:mx-0 lg:max-w-none lg:grid-cols-4'>
                            <a
                                href='https://www.abc27.com/news/us-world/business/ap-tech-companies-are-transforming-the-kitchen-with-ai-and-robots-that-do-the-cooking-2/'
                                className='col-span-2 max-h-36 w-full object-contain lg:col-span-1'
                            >
                                <img alt='Transistor' src='./abc_news_2.png' />
                            </a>
                            <a
                                href='https://www.wired.com/live/ces-2024-liveblog-3/?id=65a0272c36bab5b3557be6e7'
                                className='col-span-2 max-h-36 w-full object-contain lg:col-span-1'
                            >
                                <img alt='Reform' src='./wired.png' />
                            </a>
                            <a
                                href='https://finance.yahoo.com/news/tech-companies-transforming-kitchen-ai-152403339.html'
                                className='col-span-2 max-h-36 w-full object-contain lg:col-span-1'
                            >
                                <img alt='Tuple' src='./yahoo.png' />
                            </a>
                            <a
                                href='https://www.forbes.com/sites/andrewwatman/2025/01/08/ces-2025-best-tech-products-in-smart-cooking/'
                                className='col-span-2 max-h-36 w-full object-contain lg:col-span-1'
                            >
                                <img alt='SavvyCal' src='./forbes_2.png' />
                            </a>
                        </div>
                    </div>
                </div>
                <div className='container py-5'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='bg-black'>
                                <section
                                    aria-labelledby='features-heading'
                                    className='mx-auto max-w-7xl sm:px-2 lg:px-8 py-5'
                                >
                                    <div className='mx-auto max-w-2xl px-4 lg:max-w-none lg:px-0'>
                                        <div className='max-w-3xl'>
                                            <h4 className='text-4xl font-bold tracking-tight text-white 2xl:text-4xl border-b border-gold d-none d-sm-block'>
                                                <span className='text-gold'>
                                                    CHEF AI
                                                </span>{" "}
                                                <span
                                                    className='inline-flex gap-x-2 px-10'
                                                    style={{
                                                        fontSize: "18px",
                                                        fontWeight: 400,
                                                    }}
                                                >
                                                    <span className='text-[#B69552] hover:text-[#F2CB0F] transition-colors duration-300'>
                                                        Press
                                                    </span>
                                                    <span className='text-[#BD9B4B] hover:text-[#F8D107] transition-colors duration-300'>
                                                        Relax
                                                    </span>
                                                    <span className='text-[#C3A143] hover:text-[#FFD700] transition-colors duration-300'>
                                                        Enjoy
                                                    </span>
                                                </span>
                                            </h4>
                                            <h2
                                                id='features-heading'
                                                className='text-4xl font-bold tracking-tight text-white 2xl:text-4xl border-b border-gold d-sm-none'
                                                style={{
                                                    fontWeight: 600,
                                                    fontSize: "45px",
                                                }}
                                            >
                                                CHEF AI <br />
                                                <span
                                                    className='inline-flex gap-x-2 px-2'
                                                    style={{
                                                        fontSize: "18px",
                                                        fontWeight: 400,
                                                    }}
                                                >
                                                    <span className='text-[#B69552] hover:text-[#F2CB0F] transition-colors duration-300'>
                                                        Press
                                                    </span>
                                                    <span className='text-[#BD9B4B] hover:text-[#F8D107] transition-colors duration-300'>
                                                        Relax
                                                    </span>
                                                    <span className='text-[#C3A143] hover:text-[#FFD700] transition-colors duration-300'>
                                                        Enjoy
                                                    </span>
                                                </span>
                                            </h2>
                                            <p
                                                className='mt-4 text-white pt-4'
                                                style={{
                                                    fontWeight: 600,
                                                    fontSize: "22px",
                                                }}
                                            ></p>
                                            {activeTab === 0 && (
                                                <p className='mt-4 text-white fs-4 fs-cd-6 fw-semibold mx-5'>
                                                    Zero-touch, probeless
                                                    cooking with our AI powered
                                                    cooking device. Just put the
                                                    food in and CHEF AI does the
                                                    rest.
                                                </p>
                                            )}
                                            {activeTab === 1 && (
                                                <p className='mt-4 text-white fs-4 fs-sm-6 fw-semibold mx-5'>
                                                    Experience precision cooking
                                                    across all dimensions - our
                                                    proprietary vision and
                                                    thermal models adapt to any
                                                    food.
                                                </p>
                                            )}
                                            {activeTab === 2 && (
                                                <p className='mt-4 text-white fs-4 fs-sm-6 fw-semibold mx-5'>
                                                    Customize your device with
                                                    unique cooking preferences
                                                    that improve over time. CHEF
                                                    AI becomes your personal
                                                    chef.
                                                </p>
                                            )}
                                        </div>

                                        <TabGroup className='mt-4'>
                                            <div className='-mx-4 flex overflow-x-auto sm:mx-0'>
                                                <div
                                                    className='flex-auto px-4 sm:px-0'
                                                    style={{
                                                        overflowY: "hidden",
                                                    }}
                                                >
                                                    <div>
                                                        {/* Desktop View */}
                                                        <div className='hidden md:flex -mb-px space-x-10'>
                                                            {tabs.map(
                                                                (
                                                                    tab,
                                                                    index
                                                                ) => (
                                                                    <div
                                                                        onClick={() =>
                                                                            setActiveTab(
                                                                                index
                                                                            )
                                                                        }
                                                                        key={
                                                                            tab.name
                                                                        }
                                                                        style={{
                                                                            fontWeight: 600,
                                                                            fontSize:
                                                                                "22px",
                                                                            borderRightColor:
                                                                                "transparent",
                                                                            cursor: "pointer",
                                                                        }}
                                                                        className={`whitespace-nowrap py-6 text-lg font-medium text-gray-500 hover:text-gray-700 ${
                                                                            activeTab ===
                                                                            index
                                                                                ? "border-b-2 border-gold text-white text-gold"
                                                                                : ""
                                                                        }`}
                                                                    >
                                                                        {
                                                                            tab.name
                                                                        }
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>

                                                        {/* Mobile Dropdown */}
                                                        <div className='md:hidden w-full'>
                                                            <select
                                                                onChange={(e) =>
                                                                    setActiveTab(
                                                                        parseInt(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    )
                                                                }
                                                                value={
                                                                    activeTab
                                                                }
                                                                className='w-full py-3 px-4 text-lg font-medium text-white bg-transparent border border-gray-500 rounded-lg'
                                                                style={{
                                                                    fontWeight: 600,
                                                                    fontSize:
                                                                        "22px",
                                                                }}
                                                            >
                                                                {tabs.map(
                                                                    (
                                                                        tab,
                                                                        index
                                                                    ) => (
                                                                        <option
                                                                            key={
                                                                                tab.name
                                                                            }
                                                                            value={
                                                                                index
                                                                            }
                                                                            className='bg-gray-800'
                                                                        >
                                                                            {
                                                                                tab.name
                                                                            }
                                                                        </option>
                                                                    )
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <TabPanels as={Fragment}>
                                                {tabs.map((tab) => (
                                                    <TabPanel
                                                        key={tab.name}
                                                        className='space-y-16 pt-10 lg:pt-16'
                                                    >
                                                        {tab.features.map(
                                                            (feature) => (
                                                                <div
                                                                    key={
                                                                        feature.name
                                                                    }
                                                                    className='flex flex-col-reverse lg:grid lg:grid-cols-12 lg:gap-x-8'
                                                                >
                                                                    <div className='mt-6 lg:col-span-12 lg:mt-0'>
                                                                        <p
                                                                            className='mt-2 text-md text-gold'
                                                                            style={{
                                                                                fontWeight: 600,
                                                                            }}
                                                                        ></p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                    </TabPanel>
                                                ))}
                                            </TabPanels>
                                        </TabGroup>
                                    </div>
                                </section>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div
                                className='h-100 p-2 bg-body-tertiary rounded-3 text-white'
                                hidden={activeTab !== 0}
                            >
                                <AutonomousVideo />
                            </div>
                            <div
                                className='h-100 p-2 bg-body-tertiary rounded-3 text-white'
                                hidden={activeTab !== 1}
                            >
                                <ThermalSteakVideo />
                            </div>
                            <div
                                className='h-100 p-5 bg-body-tertiary rounded-3 text-white flex items-center justify-center'
                                hidden={activeTab !== 2}
                            >
                                <div className='flex justify-center items-center h-[700px]'>
                                    {" "}
                                    {/* Added container with fixed height to match others */}
                                    <img
                                        src='./feedback.png'
                                        className='w-[200px] h-auto max-w-full'
                                        alt='Feedback'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <hr />
            </div>

            <div
                className='gold-separator'
                style={{
                    width: "75%",
                    height: "2px",
                    background: "#ba9b5b",
                    margin: "40px auto 150px auto", // top right bottom left margins
                    display: "block",
                }}
            />

            <div className='container mt-5'>
                <div className='row row-cols-1 row-cols-md-2 g-5'>
                    {/* Card 1 */}
                    <div className='col'>
                        <div
                            className='card border-0 text-white h-100'
                            style={{
                                background:
                                    "linear-gradient(135deg, #000000 0%, #1e171f 100%)",
                                maxWidth: "650px",
                                margin: "0 auto",
                            }}
                        >
                            <div style={{ position: "relative" }}>
                                <div
                                    style={{
                                        paddingTop: "66.67%",
                                        position: "relative",
                                        overflow: "hidden",
                                    }}
                                >
                                    <img
                                        src='./angles.webp'
                                        className='card-img-top'
                                        alt='...'
                                        style={{
                                            position: "absolute",
                                            top: "0",
                                            left: "0",
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                        }}
                                    />
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            background: `radial-gradient(
                                    circle at center,
                                    transparent 20%,
                                    rgba(0, 0, 0, 0.2) 50%,
                                    rgba(0, 0, 0, 0.5) 65%,
                                    rgba(0, 0, 0, 0.9) 80%,
                                    rgba(0, 0, 0, 1) 90%
                                )`,
                                            pointerEvents: "none",
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='card-body py-4 px-5 d-flex flex-column'>
                                <p
                                    className='card-title mb-2'
                                    style={{ color: "grey", fontSize: "16px" }}
                                >
                                    Insightful
                                </p>
                                <h5 className='card-title mb-3'>
                                    All Angles Covered
                                </h5>
                                <p className='card-text text-gray-400 flex-grow-1'>
                                    Advanced computer vision recognizes your
                                    ingredients and monitors your cooking
                                    progress in real-time.
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Card 2 */}
                    <div className='col'>
                        <div
                            className='card border-0 text-white h-100'
                            style={{
                                background:
                                    "linear-gradient(135deg, #000000 0%, #1e171f 100%)",
                                maxWidth: "650px",
                                margin: "0 auto",
                            }}
                        >
                            <div style={{ position: "relative" }}>
                                <div
                                    style={{
                                        paddingTop: "66.67%",
                                        position: "relative",
                                        overflow: "hidden",
                                    }}
                                >
                                    <img
                                        src='./intelligent.jpg'
                                        className='card-img-top'
                                        alt='...'
                                        style={{
                                            position: "absolute",
                                            top: "0",
                                            left: "0",
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                        }}
                                    />
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            background: `radial-gradient(
                            circle at center,
                            transparent 30%,
                            rgba(0, 0, 0, 0.1) 40%,
                            rgba(0, 0, 0, 0.3) 65%,
                            rgba(0, 0, 0, 0.5) 75%,
                            rgba(0, 0, 0, 0.7) 90%
                        )`,
                                            pointerEvents: "none",
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='card-body py-4 px-5 d-flex flex-column'>
                                <p
                                    className='card-title mb-2'
                                    style={{ color: "grey", fontSize: "16px" }}
                                >
                                    Intelligent
                                </p>
                                <h5 className='card-title mb-3'>
                                    Precision Algorithms
                                </h5>
                                <p className='card-text text-gray-400 flex-grow-1'>
                                    CHEF AI uses thermal, weight, and
                                    dimensional data to fully understand every
                                    aspect of your food. Our intelligent cooking
                                    system processes this information to deliver
                                    the perfect cook without the need to monitor
                                    or use external probes.
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Card 3 */}
                    <div className='col'>
                        <div
                            className='card border-0 text-white h-100'
                            style={{
                                background:
                                    "linear-gradient(135deg, #000000 0%, #1e171f 100%)",
                                maxWidth: "650px",
                                margin: "0 auto",
                            }}
                        >
                            <div style={{ position: "relative" }}>
                                <div
                                    style={{
                                        paddingTop: "75%",
                                        position: "relative",
                                        overflow: "hidden",
                                    }}
                                >
                                    <img
                                        src='./versatile.png'
                                        className='card-img-top'
                                        alt='...'
                                        style={{
                                            position: "absolute",
                                            top: "0",
                                            left: "0",
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                        }}
                                    />
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            background: `radial-gradient(
                                    circle at center,
                                    transparent 20%,
                                    rgba(0, 0, 0, 0.2) 50%,
                                    rgba(0, 0, 0, 0.5) 65%,
                                    rgba(0, 0, 0, 0.9) 80%,
                                    rgba(0, 0, 0, 1) 90%
                                )`,
                                            pointerEvents: "none",
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='card-body py-4 px-5 d-flex flex-column'>
                                <p
                                    className='card-title mb-2'
                                    style={{ color: "grey", fontSize: "16px" }}
                                >
                                    Versatile
                                </p>
                                <h5 className='card-title mb-3'>
                                    Cook 100+ Foods
                                </h5>
                                <p className='card-text text-gray-400 flex-grow-1'>
                                    CHEF AI has been trained on millions of
                                    images for machine learning food
                                    classification. Find exactly what you want
                                    through intuitive guided choices.
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Card 4 */}
                    <div className='col'>
                        <div
                            className='card border-0 text-white h-100'
                            style={{
                                background:
                                    "linear-gradient(135deg, #000000 0%, #1e171f 100%)",
                                maxWidth: "650px",
                                margin: "0 auto",
                            }}
                        >
                            <div style={{ position: "relative" }}>
                                <div
                                    style={{
                                        paddingTop: "66.67%",
                                        position: "relative",
                                        overflow: "hidden",
                                    }}
                                >
                                    <img
                                        src='./redline.jpg'
                                        className='card-img-top'
                                        alt='...'
                                        style={{
                                            position: "absolute",
                                            top: "0",
                                            left: "0",
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                        }}
                                    />
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            background: `radial-gradient(
                                    circle at center,
                                    transparent 20%,
                                    rgba(0, 0, 0, 0.2) 50%,
                                    rgba(0, 0, 0, 0.5) 65%,
                                    rgba(0, 0, 0, 0.9) 80%,
                                    rgba(0, 0, 0, 1) 90%
                                )`,
                                            pointerEvents: "none",
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='card-body py-4 px-5 d-flex flex-column'>
                                <p
                                    className='card-title mb-2'
                                    style={{ color: "grey", fontSize: "16px" }}
                                >
                                    Adaptive
                                </p>
                                <h5 className='card-title mb-3'>
                                    Your Personal Chef
                                </h5>
                                <p className='card-text text-gray-400 flex-grow-1'>
                                    CHEF AI learns your tastes, making
                                    personalized suggestions effortless. It
                                    continually improves, learning from your
                                    preferences to become your personal chef.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='bg-black py-16 sm:py-24'>
                <div
                    className='mx-auto max-w-7xl sm:px-6 lg:px-8'
                    id='newsletter'
                >
                    <div
                        className='relative isolate overflow-hidden bg-gray-950 px-6 py-24 shadow-2xl sm:rounded-3xl sm:px-24 xl:py-32'
                        style={{
                            background:
                                "linear-gradient(135deg, #000000 0%, #1e171f 100%)",
                        }}
                    >
                        <h2 className='mx-auto max-w-3xl text-center text-4xl font-semibold tracking-tight text-white sm:text-5xl'>
                            Get notified when we’re launching
                        </h2>
                        <p className='mx-auto mt-6 max-w-lg text-center text-lg text-gray-300'>
                            Coming soon :)
                        </p>
                        <form
                            className='mx-auto mt-10 flex max-w-md gap-x-4'
                            onSubmit={handleSubmit}
                        >
                            <label htmlFor='email-address' className='sr-only'>
                                Email address
                            </label>
                            <input
                                id='email-address'
                                name='email'
                                type='email'
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder='Enter your email'
                                autoComplete='email'
                                className='min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm/6'
                            />
                            <button
                                type='submit'
                                disabled={status === "loading"}
                                className='flex-none rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white disabled:opacity-50'
                            >
                                {status === "loading"
                                    ? "Subscribing..."
                                    : "Notify me"}
                            </button>
                        </form>
                        {error && (
                            <p className='text-red-500 text-sm mt-2 text-center'>
                                {error}
                            </p>
                        )}
                        {status === "success" && (
                            <p className='text-green-500 text-sm mt-2 text-center'>
                                Thanks for subscribing!
                            </p>
                        )}
                        <svg
                            viewBox='0 0 1024 1024'
                            aria-hidden='true'
                            className='absolute left-1/2 top-1/2 -z-10 size-[64rem] -translate-x-1/2'
                        >
                            <circle
                                r={512}
                                cx={512}
                                cy={512}
                                fill='url(#golden-gradient)'
                                fillOpacity='0.7'
                            />
                            <defs>
                                <radialGradient
                                    r={1}
                                    cx={0}
                                    cy={0}
                                    id='golden-gradient'
                                    gradientUnits='userSpaceOnUse'
                                    gradientTransform='translate(512 512) rotate(90) scale(512)'
                                >
                                    <stop stopColor='#FFD700' />
                                    <stop
                                        offset={1}
                                        stopColor='#B8860B'
                                        stopOpacity={0}
                                    />
                                </radialGradient>
                            </defs>
                        </svg>
                    </div>
                </div>
            </div>
            <footer
                className='bg-gray-900'
                style={{
                    background:
                        "linear-gradient(135deg, #000000 0%, #1e171f 100%)",
                }}
            >
                <div className='mx-auto max-w-5xl'>
                    <div className='xl:grid xl:grid-cols-3 xl:gap-8'>
                        <div className='mt-5'>
                            <img
                                alt='Company name'
                                src='https://chefaicookware.com/chefai-transparent.svg'
                                className='h-14'
                            />
                            <p className='text-balance text-sm/6 text-gray-300'>
                                Zero touch cooking
                            </p>
                            <p className='text-balance text-sm/6 text-gray-300 mt-2'>
                                info@chefaicookware.com
                            </p>
                            <div className='flex gap-x-6'></div>
                        </div>
                        <div className='mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0'>
                            <div className='md:grid md:grid-cols-2 md:gap-8'>
                                <div>
                                    <h3 className='text-sm/6 font-semibold text-white'></h3>
                                    <ul role='list' className='mt-6 space-y-4'>
                                        <li>
                                            <a className='text-sm/6 text-gray-400 hover:text-white'></a>
                                        </li>
                                    </ul>
                                </div>
                                <div className='mt-10 md:mt-0'>
                                    <h3 className='text-sm/6 font-semibold text-white'></h3>
                                    <ul role='list' className='mt-6 space-y-4'>
                                        <li>
                                            <a className='text-sm/6 text-gray-400 hover:text-white'></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className='md:grid md:grid-cols-2 md:gap-8'>
                                <div>
                                    <h3 className='text-sm/6 font-semibold text-white'></h3>
                                    <ul role='list' className='mt-6 space-y-4'>
                                        <li>
                                            <a className='text-sm/6 text-gray-400 hover:text-white'></a>
                                        </li>
                                    </ul>
                                </div>
                                <div className='mt-10 md:mt-0'>
                                    <h3 className='text-sm/6 font-semibold text-white'></h3>
                                    <ul role='list' className='mt-6 space-y-4'>
                                        <li>
                                            <a className='text-sm/6 text-gray-400 hover:text-white'></a>
                                        </li>
                                        <li>
                                            <a className='text-sm/6 text-gray-400 hover:text-white'></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24'>
                        <p className='text-sm/6 text-gray-400'>
                            &copy; 2025 CHEFAI, Inc. All rights reserved.
                        </p>
                    </div>
                </div>
            </footer>
        </>
    );
}

// // @mui material components
// import Card from "@mui/material/Card";
// import Container from "@mui/material/Container";
// import Grid from "@mui/material/Grid";

// // Material Kit 2 PRO React components
// import MKBox from "components/MKBox";
// // import MKTypography from "components/MKTypography";

// // Material Kit 2 PRO React examples
// import DefaultNavbar from "examples/Navbars/DefaultNavbar";
// import DefaultFooter from "examples/Footers/DefaultFooter";

// // Presentation page sections
// import Counters from "pages/presentation/sections/Counters";
// import Newsletter from "pages/presentation/sections/Newsletter";

// // Routes
// import routes from "routes";
// import footerRoutes from "footer.routes";

// // Animation
// import IconCounterCard from "examples/Cards/CounterCards/IconCounterCard";
// import Animation from "./animation";
// import AnimatedChefHatIcon from "./sections/zeroTouchIcon";

// function Presentation() {
//   return (
//     <>
//       <DefaultNavbar routes={routes} transparent light />
//       <Animation />
//       <Card
//         sx={{
//           p: 2,
//           mx: { xs: 2, lg: 3 },
//           mt: -8,
//           mb: 4,
//           backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
//           backdropFilter: "saturate(200%) blur(30px)",
//           boxShadow: ({ boxShadows: { xxl } }) => xxl,
//         }}
//       >
//         <MKBox display="flex" alignItems="center" justifyContent="center" mt={20} mb={12}>
//           <iframe
//             width="600px"
//             height="400px"
//             src="https://www.youtube.com/embed/oKfMG9T19VA?autoplay=1&loop=1&playlist=oKfMG9T19VA"
//             title="CHEF AI Demo Video"
//             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//             allowFullScreen
//           ></iframe>
//         </MKBox>
//         <MKBox component="section" py={3}>
//           <Container>
//             <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
//               <Grid item xs={12} md={4}>
//                 <AnimatedChefHatIcon />
//                 <IconCounterCard
//                   count={3}
//                   color="dark"
//                   suffix="."
//                   title="Zero-Touch Cooking"
//                   description="Enjoy meals that cook themselves. Our smart kitchen tech handles everything, so you can relax while delicious dishes are prepared hands-free."
//                 />
//               </Grid>
//             </Grid>
//           </Container>
//         </MKBox>
//         <Counters />
//         <Newsletter />
//       </Card>
//       <MKBox pt={6} px={1} mt={6}>
//         <DefaultFooter content={footerRoutes} />
//       </MKBox>
//     </>
//   );
// }

// export default Presentation;
