import React, { useState, useEffect } from "react";

const ThermalSteakVideo = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const isMobile = screenWidth <= 768;

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const config = {
        circle: {
            size: 44,
            position: { x: "calc(45%)", y: "calc(55%)" },
            colors: {
                regular: {
                    fromColor: "rgba(251, 146, 60, 0.5)",
                    toColor: "rgba(251, 146, 60, 0.3)",
                },
                detail: {
                    fromColor: "rgba(251, 146, 60, 0.5)",
                    toColor: "rgba(251, 146, 60, 0.3)",
                },
            },
        },
        grid: {
            borderColor: "rgba(251, 146, 60, 0.3)",
            backgroundColor: "rgba(186, 155, 91, 0.05)",
            borderWidth: "1px",
            cols: 8,
            rows: 8,
            zIndex: 10,
        },
        detailBox: {
            width: 110,
            height: 110,
            offsetX: -224,
            offsetY: -100,
            zIndex: 30,
            boxBgColor: "rgba(0, 0, 0, 0.3)",
            borderColor: "rgba(251, 146, 60, 1)",
        },
    };

    // Simplified temperature points for mobile
    const tempPoints = [
        {
            x: config.circle.position.x,
            y: config.circle.position.y,
            temp: "145°F",
            title: "Surface",
            detail: !isMobile, // Only show detail view on desktop
        },
        {
            x: "calc(60%)",
            y: "calc(45%)",
            temp: "135°F",
            title: "Center",
        },
        {
            x: "calc(75%)",
            y: "calc(35%)",
            temp: "140°F",
            title: "Edge",
        },
    ];

    const ProjectionLine = ({ startOffset, endPoint }) => (
        <line
            x1={startOffset.x}
            y1={startOffset.y}
            x2={endPoint.x}
            y2={endPoint.y}
            stroke='rgba(186, 155, 91, 0.8)'
            strokeWidth={2.5}
            strokeDasharray='6 3'
        />
    );

    return (
        <div className='h-[700px] w-full bg-black rounded-lg flex items-center justify-center'>
            <div className='relative w-full aspect-video'>
                {/* Base images */}
                <div className='absolute inset-0'>
                    <img
                        src='./steak.png'
                        alt='Steak'
                        className='absolute inset-0 w-full h-full object-contain'
                    />
                    <img
                        src='./steak-and-thermometer.png'
                        alt='Steak with Thermometer'
                        className='absolute inset-0 w-full h-full object-contain animate-thermometer'
                    />
                </div>

                {/* Overlay elements */}
                <div className='absolute inset-0 z-20 animate-overlay'>
                    {/* Grid overlay */}
                    <div className='absolute inset-0 pointer-events-none'>
                        <div
                            className='absolute inset-0'
                            style={{
                                display: "grid",
                                gridTemplateColumns: `repeat(${config.grid.cols}, minmax(0, 1fr))`,
                                gridTemplateRows: `repeat(${config.grid.rows}, minmax(0, 1fr))`,
                            }}
                        >
                            {[
                                ...Array(config.grid.cols * config.grid.rows),
                            ].map((_, i) => (
                                <div
                                    key={i}
                                    style={{
                                        border: `${config.grid.borderWidth} solid ${config.grid.borderColor}`,
                                        backgroundColor:
                                            config.grid.backgroundColor,
                                    }}
                                />
                            ))}
                        </div>
                    </div>

                    {/* Temperature points */}
                    <div className='absolute inset-0 pointer-events-none'>
                        {tempPoints.map((point, i) => (
                            <div
                                key={i}
                                className='absolute'
                                style={{
                                    left: point.x,
                                    top: point.y,
                                    transform: "translate(-50%, -50%)",
                                    zIndex: point.detail
                                        ? config.detailBox.zIndex
                                        : config.grid.zIndex + 1,
                                }}
                            >
                                <div className='relative'>
                                    <div
                                        className='rounded-full'
                                        style={{
                                            width: config.circle.size,
                                            height: config.circle.size,
                                            background: `linear-gradient(to bottom right, 
                                                ${
                                                    point.detail
                                                        ? config.circle.colors
                                                              .detail.fromColor
                                                        : config.circle.colors
                                                              .regular.fromColor
                                                }, 
                                                ${
                                                    point.detail
                                                        ? config.circle.colors
                                                              .detail.toColor
                                                        : config.circle.colors
                                                              .regular.toColor
                                                }
                                            )`,
                                        }}
                                    />

                                    {/* Always show temperature for mobile, or for non-detail points */}
                                    {(!point.detail || isMobile) && (
                                        <div className='absolute inset-0 flex items-center justify-center text-white text-xs font-mono'>
                                            {point.temp}
                                        </div>
                                    )}

                                    {/* Detail view - only show on desktop */}
                                    {point.detail && !isMobile && (
                                        <div className='absolute left-0 top-0 pointer-events-none'>
                                            {/* SVG container for projection lines */}
                                            <svg
                                                className='absolute'
                                                style={{
                                                    left: config.detailBox
                                                        .offsetX,
                                                    top: config.detailBox
                                                        .offsetY,
                                                    width:
                                                        Math.abs(
                                                            config.detailBox
                                                                .offsetX
                                                        ) + config.circle.size,
                                                    height:
                                                        config.detailBox
                                                            .height +
                                                        config.circle.size,
                                                }}
                                            >
                                                <ProjectionLine
                                                    startOffset={{
                                                        x: 230,
                                                        y: 105,
                                                    }}
                                                    endPoint={{ x: 0, y: 0 }}
                                                />
                                                <ProjectionLine
                                                    startOffset={{
                                                        x: 230,
                                                        y: 105,
                                                    }}
                                                    endPoint={{ x: 105, y: 0 }}
                                                />
                                                <ProjectionLine
                                                    startOffset={{
                                                        x: 225,
                                                        y: 130,
                                                    }}
                                                    endPoint={{
                                                        x: 105,
                                                        y: 107,
                                                    }}
                                                />
                                                <ProjectionLine
                                                    startOffset={{
                                                        x: 225,
                                                        y: 130,
                                                    }}
                                                    endPoint={{ x: 0, y: 107 }}
                                                />
                                            </svg>

                                            {/* Detail box */}
                                            <div
                                                className='absolute rounded-lg overflow-hidden'
                                                style={{
                                                    left: config.detailBox
                                                        .offsetX,
                                                    top: config.detailBox
                                                        .offsetY,
                                                    width: config.detailBox
                                                        .width,
                                                    height: config.detailBox
                                                        .height,
                                                    border: `1px solid ${config.detailBox.borderColor}`,
                                                    backgroundColor:
                                                        config.detailBox
                                                            .boxBgColor,
                                                }}
                                            >
                                                <div className='w-full h-full flex items-center justify-center'>
                                                    <div
                                                        className='w-16 h-16 rounded-full flex items-center justify-center'
                                                        style={{
                                                            background: `linear-gradient(to bottom right, ${config.circle.colors.detail.fromColor}, ${config.circle.colors.detail.toColor})`,
                                                        }}
                                                    >
                                                        <span className='text-xs text-white font-mono'>
                                                            145°F
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <style>
                    {`
                        @keyframes thermometer {
                            0% { opacity: 0; }
                            5%, 30% { opacity: 1; }
                            45%, 100% { opacity: 0; }
                        }

                        @keyframes overlay {
                            0%, 45% { opacity: 0; }
                            60%, 85% { opacity: 1; }
                            95%, 100% { opacity: 0; }
                        }

                        .animate-thermometer {
                            animation: thermometer 10s infinite;
                        }

                        .animate-overlay {
                            animation: overlay 10s infinite;
                        }
                    `}
                </style>
            </div>
        </div>
    );
};

export default ThermalSteakVideo;
