import { useState, useRef, useEffect } from "react";

const AutonomousVideo = () => {
    const [activeVideo, setActiveVideo] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const videoRefs = [useRef(null), useRef(null)];

    const desktopVideos = [
        "./Automatic-Door-Close-compressed.mp4",
        "./Coils-On-Off-compressed.mp4",
    ];
    const mobileVideos = [
        "./Automatic-Door-Close-mobile-compressed.mp4",
        "./Coils-On-Off-Mobile-compressed.mp4",
    ];

    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        checkMobile();
        window.addEventListener("resize", checkMobile);
        return () => window.removeEventListener("resize", checkMobile);
    }, []);

    useEffect(() => {
        const handleVideoEnd = () => {
            setIsTransitioning(true);
            setTimeout(() => {
                setActiveVideo((prev) => (prev + 1) % 2);
                setIsTransitioning(false);
            }, 1000);
        };

        const currentVideo = videoRefs[activeVideo].current;
        if (currentVideo) {
            currentVideo.addEventListener("ended", handleVideoEnd);
            return () =>
                currentVideo.removeEventListener("ended", handleVideoEnd);
        }
    }, [activeVideo]);

    useEffect(() => {
        if (!isTransitioning) {
            const currentVideo = videoRefs[activeVideo].current;
            if (currentVideo) {
                currentVideo.play().catch((error) => {
                    console.error("Error playing video:", error);
                });
            }
        }
    }, [activeVideo, isTransitioning]);

    const getCurrentVideos = () => (isMobile ? mobileVideos : desktopVideos);

    return (
        <div className='relative w-full h-[500px] md:h-[700px]'>
            {" "}
            {/* Adjusted height and removed outer margin */}
            <div className='absolute inset-0 bg-black rounded-lg overflow-hidden'>
                {getCurrentVideos().map((video, index) => (
                    <video
                        key={video}
                        ref={videoRefs[index]}
                        className={`absolute inset-0 w-full h-full object-contain transition-opacity duration-1000 ${
                            activeVideo === index && !isTransitioning
                                ? "opacity-100"
                                : "opacity-0"
                        }`}
                        muted
                        playsInline
                        autoPlay={index === 0}
                        preload='auto'
                    >
                        <source src={video} type='video/mp4' />
                    </video>
                ))}
            </div>
        </div>
    );
};

export default AutonomousVideo;
