import React from "react";

const BrandButton = ({ children, href, className = "" }) => {
    return (
        <a
            href={href}
            className={`
        relative
        inline-flex
        items-center
        justify-center
        px-3.5
        py-2.5
        text-lg
        font-semibold
        text-black
        rounded-md
        overflow-hidden
        transition-all
        duration-300
        bg-gradient-to-r
        from-[#ba9b5b]
        via-[#e3c688]
        to-[#ba9b5b]
        hover:from-[#DEB925]
        hover:via-[#e3c688]
        hover:to-[#DEB925]
        shadow-md
        hover:shadow-lg
        hover:-translate-y-0.5
        active:translate-y-0.5
        before:absolute
        before:content-['']
        before:w-full
        before:h-full
        before:bg-gradient-to-r
        before:from-transparent
        before:via-white/10
        before:to-transparent
        before:-translate-x-full
        before:animate-subtle-shimmer
        before:top-0
        before:left-0
        ${className}
      `}
        >
            {children}
        </a>
    );
};

export default BrandButton;
