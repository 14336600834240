import { useEffect, useState } from "react";

export default function ResponsiveMainVideo() {
    const [isMobile, setIsMobile] = useState(false);
    const [videoEnded, setVideoEnded] = useState(false);

    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        // Check initially
        checkMobile();

        // Add resize listener
        window.addEventListener("resize", checkMobile);

        // Cleanup
        return () => window.removeEventListener("resize", checkMobile);
    }, []);

    const getVideoSource = () =>
        isMobile
            ? "./rotating-product-mobile-compressed.mp4"
            : "./rotating-product-compressed.mp4";

    const getPosterImage = () =>
        isMobile ? "./product-static-mobile.jpg" : "./product-static.jpg";

    return (
        <div className='absolute inset-0 -z-10'>
            <video
                className={`absolute inset-0 h-full w-full object-cover transition-opacity duration-1000 ${
                    videoEnded ? "opacity-0" : "opacity-100"
                }`}
                autoPlay
                muted
                playsInline
                onEnded={() => setVideoEnded(true)}
                poster={getPosterImage()}
                style={{
                    filter: "brightness(0.6)",
                    objectPosition: "center center",
                }}
            >
                <source src={getVideoSource()} type='video/mp4' />
            </video>
            {/* Static fallback image */}
            <img
                alt='CHEF AI Prototype'
                src={getPosterImage()}
                className={`absolute inset-0 h-full w-full object-cover transition-opacity duration-1000 ${
                    videoEnded ? "opacity-100" : "opacity-0"
                }`}
                style={{
                    filter: "brightness(0.6)",
                    objectPosition: "center center",
                }}
            />
        </div>
    );
}
